import React from "react";
import BlogHeader from "./BlogHeader";
// import BlogList from "./BlogList";

const BlogContent = ({}: any) => {

    return (
        <div className="content-wrapper">
            <BlogHeader/>
            {/*<BlogList/>*/}
        </div>
    )

}

export default BlogContent;
