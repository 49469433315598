import React, {useState} from "react";
import {loadScript} from "../../util/helper";
import Layout from "../../components/layout/Layout";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import BlogContent from "../../components/blog/BlogContent";
import {graphql} from "gatsby";


const OhadarbBlog = ({}: any) => {

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadScript("/assets/js/plugins.js");
        const timer = setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 1000)
        setLoading(true)
        return () => {
            clearTimeout(timer);
            setLoading(false)
        }
    }, [loading])

    return (
        <>
            <Helmet>
                <title>OHADA | OHADA Arbitration Blog</title>
            </Helmet>
            <Layout title={'OHADARB | Blog'}>
                {!loading && <div className="page-loader"></div> }
                <BlogContent/>
            </Layout>
        </>
    )
}

export default OhadarbBlog;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
